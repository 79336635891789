import { Component, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [NgbTooltip],
  selector: 'wifi-strength-indicator',
  template: `<div
    [class.good]="signal >= 66"
    [class.ok]="signal >= 33 && signal < 66"
    [class.bad]="signal < 33"
    class="sizing-box"
    ngbTooltip="{{ signal }}%"
  >
    <!-- <div class="first-bar bar"></div>
  <div class="second-bar bar"></div>
  <div class="third-bar bar"></div>
  <div class="fourth-bar bar"></div>
  <div class="fifth-bar bar"></div>
  <div class="sixth-bar bar"></div>
  <div class="seventh-bar bar"></div>
  <div class="eight-bar bar"></div>
  <div class="ninth-bar bar"></div>
  <div class="tenth-bar bar"></div> -->
    <div
      class="bar"
      [style.clip-path]="
        'polygon(' +
        signal +
        '% ' +
        (100 - signal) +
        '%, 0% 100%, ' +
        signal +
        '% 100%)'
      "
    ></div>
  </div> `,
  styleUrls: ['./wifi-strength-indicator.component.scss'],
})
export class WifiStrengthIndicatorComponent {
  @Input() signal!: number;
}
